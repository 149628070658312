const lottiePlayers = () => {
    const lottiePlayers = document.querySelectorAll("lottie-player");
    for (var i = 0; i < lottiePlayers.length; i++) {
        if (lottiePlayers[i].dataset.hasOwnProperty("src")) {
            lottiePlayers[i].addEventListener("load", function(e){
                this.play();
            });
            lottiePlayers[i].load(lottiePlayers[i].dataset.src);
        }
    }
}
export default lottiePlayers;
