const loginPage = () => {
    const showPassBtns = document.querySelectorAll(".login-page__show-password");
    if (showPassBtns.length > 0) {
        for (let i = 0; i < showPassBtns.length; i++) {
            showPassBtns[i].addEventListener("click", function(){
                this.parentElement.classList.toggle("show-password");
                this.nextElementSibling.type === "password" ? this.nextElementSibling.type = "text" : this.nextElementSibling.type = "password";
            });
        }
    }
    const registrationForm = document.querySelector(".login-page__form--registration");
    if (registrationForm) {
        registrationForm.addEventListener("submit", function(e){
            // e.preventDefault();
            // console.log(e);
            
        });
    }

}
export default loginPage;
