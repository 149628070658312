class IframeResizer {
    constructor(settings = {}) {
        this.elementsSelector;
        this.elements;
        this.debug = false;
        Object.assign(this, settings);
        this.init();
    }
    init() {
        this.getElements();
        if (self.debug) {
            console.log(this);
        }
    }
    getElements() {
        const self = this;
        if (document.readyState == "complete") {
            self.elements = document.querySelectorAll(self.elementsSelector);
            self.setSize();
        }
        else {
            window.addEventListener("load", function(e){
                self.elements = document.querySelectorAll(self.elementsSelector);
                self.setSize();
            });
        }
    }
    setSize() {
        const self = this;
        let iframes = self.elements;
        for (var i = 0; i < iframes.length; i++) {
            let iframeDoc = iframes[i].contentDocument || iframes[i].contentWindow.document;
            if (self.debug) {
                console.log("----Iframe " + i + "----");
                console.log(iframeDoc);
                console.log("----readyState----");
                console.log(iframeDoc.readyState);
            }
            if (iframeDoc.readyState == "complete") {
                iframes[i].classList.add("loaded");
                iframes[i].style.height = iframes[i].contentDocument.querySelector('body').offsetHeight + 'px';
                iframes[i].classList.add("resized");
            }
            else {
                iframes[i].addEventListener("load", function(e){
                    this.classList.add("loaded");
                    this.style.height = this.contentDocument.querySelector('body').offsetHeight + 'px';
                    this.classList.add("resized");
                });
            }
        }
    }
}

export default IframeResizer;
