const toc = () => {
    function getElementDistance(elem) {
        var location = 0;
        if (elem.offsetParent) {
            do {
                location += elem.offsetTop;
                elem = elem.offsetParent;
            } while (elem);
        }
        return location >= 0 ? location : 0;
    }
    const tocItems = document.getElementsByClassName("toc-item");
    if (tocItems.length) {
        for (var i = 0; i < tocItems.length; i++) {
            tocItems[i].addEventListener("click", function(e){
                e.preventDefault();
                const item = document.getElementById(this.href.split("#")[1]);
                window.scrollTo(0, getElementDistance(item) - 85);
            });
        }
    }
}
export default toc;
