const loginTop = () => {
    const loginElement = document.querySelector(".login-top");
    if (loginElement) {
        const loginBtn = loginElement.querySelector(".login-top__top-btn");
        loginBtn.addEventListener("click", function(){
            loginElement.classList.toggle("open");
        });
    }
}

export default loginTop;
