import IframeResizer from './components/IframeResizer';
import loginPage from "./pages/login";
import loginTop from "./components/login-top";
import lottiePlayers from "./components/lottiePlayers";
import toc from "./components/toc";


document.addEventListener('DOMContentLoaded', function() {
    lottiePlayers();
    loginPage();
    loginTop();
    toc();

    // Single help display - START

    var helpBtn = document.getElementsByClassName('access-info__bottom-info')[0];
    var stepsWrapper = document.getElementsByClassName('access-info__bottom-info-steps')[0];
    var nextSteps    = document.getElementsByClassName('access-info__bottom-info-steps-step-btn');
    var allSteps     = document.getElementsByClassName('access-info__bottom-info-steps-step');
    var closeSteps   = document.getElementsByClassName('access-info__bottom-info-steps-step-top-close');
    var backgroundElement = document.getElementsByClassName('background-on-page')[0];

    if(helpBtn) {
        helpBtn.addEventListener('click',function(){
            if(stepsWrapper.classList.contains('show')) {
                stepsWrapper.classList.remove('show');
                backgroundElement.classList.remove('show');
            } else {
                stepsWrapper.classList.add('show');
                backgroundElement.classList.add('show');
            }
        })
    }

    if(nextSteps) {
        for(var i = 0; i < nextSteps.length; i++) {
            nextSteps[i].addEventListener('click',function(){
                if(this.dataset.step == 1) {
                    allSteps[0].classList.remove('active');
                    allSteps[0].classList.add('inactive');
                    allSteps[1].classList.remove('inactive');
                    allSteps[1].classList.add('active');
                }
                if(this.dataset.step == 2) {
                    allSteps[1].classList.remove('active');
                    allSteps[1].classList.add('inactive');
                    allSteps[2].classList.remove('inactive');
                    allSteps[2].classList.add('active');
                }
                if(this.dataset.step == 3) {
                    backgroundElement.classList.remove('show');
                    stepsWrapper.classList.remove('show');
                    setTimeout(() => {
                        allSteps[2].classList.remove('inactive');
                        allSteps[2].classList.remove('active');
                        allSteps[1].classList.remove('inactive');
                        allSteps[0].classList.remove('inactive');
                        allSteps[0].classList.add('active');
                    }, 300);
                }
            })
        }
    }

    if(closeSteps) {
        for(var i = 0; i < closeSteps.length; i++) {
            closeSteps[i].addEventListener('click',function(){
                backgroundElement.classList.remove('show');
                stepsWrapper.classList.remove('show');
            })
        }
    }

    if(backgroundElement) {
        backgroundElement.addEventListener('click',function(e) {
            if(e.target.classList.contains('show')) {
                backgroundElement.classList.remove('show');
                stepsWrapper.classList.remove('show');
            }
        })
    }

    // Single help display - END


    var burgerProgress = document.getElementsByClassName('login-info__status-progress');
    var burgerBar      = document.getElementsByClassName('login-info__status-progress-bar');

    if(burgerProgress) {
        for(var i = 0; i < burgerProgress.length; i++) {
            var daysLeft = burgerProgress[i].dataset.timeLeft;
            var daysMax  = burgerProgress[i].dataset.timeMax;
            var width = daysLeft / daysMax * 100;
            var bar = burgerProgress[i].getElementsByClassName('login-info__status-progress-bar')[0];
            bar.style.width = width + '%';
        }
    }

    var personInfoBtn = document.querySelector('.new-header__middle-login .green-btn');
    var personInfoWrapper = document.getElementsByClassName('new-header__middle-login-info')[0];
    var personInfoClose   = document.getElementsByClassName('new-header__middle-login-info-close')[0];

    if(personInfoBtn) {
        personInfoBtn.addEventListener('click',function(el) {
            if(el.target.classList.contains('green-btn')) {
                personInfoWrapper && personInfoWrapper.classList.toggle('show');
            }
        })
    }
    if(personInfoClose) {
        personInfoClose.addEventListener('click',function(){
            personInfoWrapper.classList.remove('show');
        })
    }

    const monthlyProductsMain = document.getElementsByClassName('monthly-products-main__swiper')[0];

    if(monthlyProductsMain) {
        var swiper = new Swiper(".monthly-products-main__swiper", {
            slidesPerView: 'auto',
            spaceBetween: 30,
        });
    }
})
